import * as React from 'react';
import {Content, ContentProps} from '../components/content';

class About extends React.Component<ContentProps> {
  render() {
    const {id, containerView} = this.props;
    return (<Content id={id} containerView={containerView}>
        <h1>Hello 👋</h1>
        <p>I'm Lizzie (she/her), a software engineer based in Louisville, CO.</p>
        <p>I currently build tools for scientists at <a href="https://www.lilly.com/">Eli Lilly</a>.</p>
    </Content>)
  }
}

export default About;